/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-500 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-800 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-300italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-500italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-500italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-800italic - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/open-sans-v34-latin/open-sans-v34-latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }


/* abril-fatface-regular - latin */
@font-face {
    font-family: 'Abril Fatface';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/abril-fatface-v19-latin/abril-fatface-v19-latin-regular.svg#AbrilFatface') format('svg'); /* Legacy iOS */
  }

/* montaga-regular - latin */
@font-face {
    font-family: 'Montaga';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/montaga-v13-latin/montaga-v13-latin-regular.svg#Montaga') format('svg'); /* Legacy iOS */
  }


  /* sansita-swashed-300 - latin */
@font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-300.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-regular - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-regular.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-500 - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-500.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-600 - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-600.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-700 - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-700.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-800 - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-800.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }
  /* sansita-swashed-900 - latin */
  @font-face {
    font-family: 'Sansita Swashed';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/sansita-swashed-v17-latin/sansita-swashed-v17-latin-900.svg#SansitaSwashed') format('svg'); /* Legacy iOS */
  }

  /* fredoka-one-regular - latin */
@font-face {
    font-family: 'Fredoka One';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/fredoka-one-v13-latin/fredoka-one-v13-latin-regular.svg#FredokaOne') format('svg'); /* Legacy iOS */
  }
  
/* passion-one-regular - latin */
@font-face {
    font-family: 'Passion One';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-regular.svg#PassionOne') format('svg'); /* Legacy iOS */
  }
  /* passion-one-700 - latin */
  @font-face {
    font-family: 'Passion One';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-700.svg#PassionOne') format('svg'); /* Legacy iOS */
  }
  
  /* passion-one-900 - latin */
  @font-face {
    font-family: 'Passion One';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/passion-one-v16-latin/passion-one-v16-latin-900.svg#PassionOne') format('svg'); /* Legacy iOS */
  }

  /* bebas-neue-regular - latin */
@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/bebas-neue-v9-latin/bebas-neue-v9-latin-regular.svg#BebasNeue') format('svg'); /* Legacy iOS */
  }

/* playball-regular - latin */
@font-face {
    font-family: 'Playball';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/playball-v16-latin/playball-v16-latin-regular.svg#Playball') format('svg'); /* Legacy iOS */
  }

  /* allan-regular - latin */
@font-face {
    font-family: 'Allan';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-regular.svg#Allan') format('svg'); /* Legacy iOS */
  }
  /* allan-700 - latin */
  @font-face {
    font-family: 'Allan';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/allan-v20-latin/allan-v20-latin-700.svg#Allan') format('svg'); /* Legacy iOS */
  }

  /* seaweed-script-regular - latin */
@font-face {
    font-family: 'Seaweed Script';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/seaweed-script-v13-latin/seaweed-script-v13-latin-regular.svg#SeaweedScript') format('svg'); /* Legacy iOS */
  }

  /* dekko-regular - latin */
@font-face {
    font-family: 'Dekko';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/dekko-v19-latin/dekko-v19-latin-regular.svg#Dekko') format('svg'); /* Legacy iOS */
  }

  /* lato-100 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-100italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-300 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-300italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v23-latin/lato-v23-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
  }

  /* poppins-100 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-100italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-100italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-300 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-300italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/poppins-v20-latin/poppins-v20-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* roboto-condensed-300 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-300italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-regular - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700 - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700italic - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }

  /* merriweather-300 - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-300italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-regular - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-700 - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-700italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-900 - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-900italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/merriweather-v30-latin/merriweather-v30-latin-900italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }