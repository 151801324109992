@import "./fonts.css";

@font-face {
  font-family: 'Comic Sans MS';
  src: url("../src/assets/fonts/comici.ttf");
}


body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body .webinars-list.empty .empty-state .fake-radio{
  display: flex;
  justify-content: center;
  align-items: center;
}
body .webinars-list.empty .empty-state .fake-radio::after{
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(189 189 189);
  border-radius: 50%;
}