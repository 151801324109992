*,
*::after,
*::before {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

html,
body,
#wrapper {
  min-height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "American Typewriter";
  src: url("../src/assets/fonts/americanTypewriter2.woff2") format("woff2");
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
body .webinars-list.empty .empty-state .fake-radio{
  display: flex;
  justify-content: center;
  align-items: center;
}
body .webinars-list.empty .empty-state .fake-radio::after{
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(189 189 189);
  border-radius: 50%;
}

.calendly-overlay .calendly-popup{
  max-height: 700px;
}

.calendly-overlay{
  z-index: 999999;
}